h1, h2, h3, h4, h5{
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

h3{
  font-size: 18px;
  margin: 0;
}

.header {
  background-color: #d3effb;
  color: #2464af;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header img{
  width: 70px;
}

.details_div{
  margin-bottom: 30px;
  width: 500px;
  max-width: 65vw;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.header_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  align-content: stretch;
}

.header_left{
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}

.booking_portal{
  margin: 30px 0px 30px 80px;
  display: flex;
  align-items: center;
  border-left-width: 2px;
  border-left-color: grey;
  border-left-style: solid;
}

@media(max-width: 1230px)
{
  .booking_portal{
    display: none;
  }
}

.small_screen{
  display: none;
  margin-left: 20px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(36, 100, 175, 0.8);
  color: white;
  padding: 10px 20px 10px 20px;
}

@media(max-width: 910px)
{
  .call h3{
    font-size: 15px;
  }
}

@media(max-width: 810px)
{
  .small_screen h3{
    font-size: 15px;
  }

  .small_screen
  {
    display: flex;
    justify-content: center;
  }

  .call_inner_right{
    display: none !important;
  }
}

@media(max-width: 620px)
{
  .call{
    display: block !important;
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .small_screen{
    margin: 0px !important;
    width: 230px;
  }

  .call_inner{
    margin-bottom: 5px;
    width: 230px;
  }
}

.call_inner{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(36, 100, 175, 0.8);
  color: white;
  padding: 10px 20px 10px 20px;
}

.call_inner_right{
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(36, 100, 175, 0.8);
  color: white;
  padding: 10px 20px 10px 20px;
}

.call{
  display: flex;
  margin-left: 10px;
}

footer{
  background-color: #d3effb;
  color: #2464af;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 50px;
  padding-left: 50px;
}

@media(max-width: 402px)
{
  footer
  {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.footer_menu{
  display: flex;
  justify-content: space-evenly;
}

.footer_h4:hover{
  text-decoration: underline;
}

.neural_solutions:hover{
  text-decoration: underline;
}

.step_one{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.step_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.step_three{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

p{
  font-family: 'Roboto', sans-serif;
}

.details_p{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  position: relative;
  margin: 0px
}

.App {
  text-align: center;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
}

.body {
  font-size: calc(10px + 2vmin);
  font-family: 'Roboto', sans-serif;
  margin: 0px 0px 100px 0px;
}

@media(max-width: 641px)
{
  .body
  {
    margin-bottom: 160px;
  }
}


.body_content
{
  padding-top: 20px;
  padding-bottom: 20px;
}

@media(max-width: 450px)
{
  .body
  {
    margin: 0px 0px 160px 0px;
  }

  .body_content
  {
    border: 0;
  }

  .header_flex{
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .call{
    margin-left: 5vw;
  }

}

@media(max-width: 350px)
{
  .call
  {
    display: none !important;
  }

  .header_flex{
    justify-content: center;
  }
}


h1{
  margin: 0px 0px 0px 0px;
}

img{
  max-width:100%;
}

h1{
  font-size: 30px;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.calendar{
  width: 320px;
  margin: 0px 20px 30px 20px;
}

.time{
  height: 305px;
  width: 320px;
  margin: 0px 20px 30px 20px;
}

.details_confirmation{
  margin-top: 50px;
  border: 1px solid #2464af;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*.captcha_large{*/
/*  display: flex;*/
/*  justify-content: center;*/
/*}*/

/*@media (max-width: 450px)*/
/*{*/
/*  .captcha_large{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    transform:scale(0.7) !important;*/
/*    -webkit-transform:scale(0.7) !important;*/
/*  }*/
/*}*/
